
<!--DESKTOP-->
<div *ngIf="!isMobilePlatform"  #title class="title-container" [ngClass]="{'hide-empty-dashboard-line': students?.list?.length === 0}">
    <div class="title"><span>{{ 'student-dashboard-table.title.students' | translate }}</span></div>
    <!-- <div class="btn-container custom-theme">
        <div *ngIf="user.role !== UserRoles.Teacher">
            <button mat-button (click)="addOrEditStudent()">{{ 'student-dashboard-table.button.addStudent' | translate }}</button>
        </div>
        <button  [disabled]="true" style="opacity: 0.3" mat-stroked-button>{{ 'student-dashboard-table.button.copy-invite-link' | translate }}</button>
    </div> -->
</div>
<div *ngIf="students?.list && students.list.length > 0 && !isMobilePlatform; else emptyDesktopContainer" class="table-container">
        <table matSort (matSortChange)="sortData($event)" mat-table [dataSource]="dataSource">
            <!-- First Name Column -->
            <ng-container matColumnDef="firstName">
                <th mat-sort-header="firstName" mat-header-cell *matHeaderCellDef>{{ 'student-dashboard-table.header.first-name' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{element.firstName}} </td>
            </ng-container>

            <!-- Last Name Column -->
            <ng-container matColumnDef="lastName" sticky>
                <th mat-sort-header="lastName" mat-header-cell *matHeaderCellDef>{{ 'student-dashboard-table.header.last-name' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{element.lastName ? element.lastName : '—'}} </td>
            </ng-container>

            <!-- Instrument Column-->
            <ng-container matColumnDef="instrument">
                <th mat-header-cell *matHeaderCellDef> {{ 'student-dashboard-table.header.instruments' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.student?.instruments">
                      <span *ngFor="let instrument of element.student?.instruments; let last = last">
                         {{ currentLanguage === 'eng' ? instrument.nameEn : instrument.nameDe }}
                      <span *ngIf="!last">, </span>
                     </span>
                    </ng-container>
                    <span *ngIf="!element.student?.instruments || element.student?.instruments?.length === 0">—</span>
                </td>
            </ng-container>

            <!-- Sign-up Date Column -->
            <ng-container matColumnDef="signUpDate">
                <th mat-sort-header="created" mat-header-cell *matHeaderCellDef> {{ 'school-dashboard-table.header.sign-up.date' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{ element?.created ? (element?.created | formatLessonStartTime) : '—'}} </td>
            </ng-container>

            <!-- Instrument Genres-->
            <ng-container matColumnDef="genres">
                <th mat-header-cell *matHeaderCellDef> {{ 'student-dashboard-table.header.genres' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element.student?.genres">
                     <span *ngFor="let genre of element.student?.genres; let last = last">
                         {{ currentLanguage === 'eng' ? genre.nameEn : genre.nameDe }}
                         <span *ngIf="!last">, </span>
                    </span>
                    </ng-container>
                    <span *ngIf="!element.student?.genres || element.student?.genres?.length === 0">—</span>
                </td>
            </ng-container>

            <!-- Approval status -->
            <ng-container matColumnDef="approvalStatus">
                <th mat-sort-header="validated" mat-header-cell *matHeaderCellDef> {{ 'student-dashboard-table.header.approval-status' | translate }} </th>
                <td mat-cell *matCellDef="let element; let i = index"> {{students.list[i].validated ? ('student-dashboard-table.status.approved' | translate) : ('student-dashboard-table.status.pending' | translate)}} </td>
            </ng-container>

            <!-- Time Column-->
            <ng-container matColumnDef="time">
                <th mat-header-cell *matHeaderCellDef> {{ 'student-dashboard-table.header.time' | translate }} </th>
                <td mat-cell *matCellDef="let element"> {{studentLessonTimeAndDuration(element?.student?.id)?.startTime ? studentLessonTimeAndDuration(element?.student?.id)?.startTime : '—'}} </td>
            </ng-container>

            <!-- Duration Column -->
            <ng-container matColumnDef="duration">
                <th mat-header-cell *matHeaderCellDef> {{ 'student-dashboard-table.header.duration' | translate }}</th>
                <td mat-cell *matCellDef="let element"> {{studentLessonTimeAndDuration(element?.student?.id)?.duration ? studentLessonTimeAndDuration(element?.student?.id)?.duration : '—'}} </td>
            </ng-container>

               <!-- Contact Status Column -->
            <!-- <ng-container matColumnDef="contact" stickyEnd>
                <th mat-header-cell *matHeaderCellDef>{{ 'student-dashboard-table.header.actions' | translate }}</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item>
                            <a class="menu-link" href="mailto:{{ element.email }}">{{ element.email }}</a>
                        </button>
                        <button *ngIf="element.phoneNumber" mat-menu-item>
                            <a class="menu-link" href="tel:{{ element.phoneNumber }}">{{ element.phoneNumber }}</a>
                        </button>
                    </mat-menu>
                    <div class="actions-container">
                        <button [matMenuTriggerFor]="menu" mat-button>{{ 'student-dashboard-table.button.contact' | translate }}</button>
                    </div>
                </td>
            </ng-container>
            -->

            <ng-container matColumnDef="approve" stickyEnd>
                <ng-container *ngIf="user.role === UserRoles.Teacher">
                <th mat-header-cell *matHeaderCellDef>{{ 'student-dashboard-table.header.actions' | translate }}</th>
                <td mat-cell *matCellDef="let element; let i = index">
                    <div class="actions-container">
                        <button [disabled]="!hasApprovePermission" *ngIf="students.list[!this.currentIndex ? i : this.currentIndex + i]?.student?.teacherId" mat-button (click)="disapprove(students.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'student-dashboard-table.button.disapprove' | translate }}</button>
                        <button [disabled]="!hasApprovePermission" *ngIf="!students.list[!this.currentIndex ? i : this.currentIndex + i]?.student?.teacherId" mat-button class="raised-button" color="primary" mat-raised-button (click)="approve(students.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'student-dashboard-table.button.approve' | translate }}</button>
                    </div>
                </td>
                </ng-container>
                <ng-container *ngIf="user.role !== UserRoles.Teacher">
                    <th style="display: none" mat-header-cell *matHeaderCellDef></th>
                    <td style="display: none" mat-cell *matCellDef="let element; let i = index">
                    </td>
                </ng-container>
            </ng-container>

            <ng-container matColumnDef="profile" stickyEnd>
                <th mat-header-cell [ngClass]="{'no-teacher': user.role !== UserRoles.Teacher, 'teacher': user.role === UserRoles.Teacher}" *matHeaderCellDef>{{ user.role !== UserRoles.Teacher ? ('student-dashboard-table.header.actions' | translate) : '' }}</th>
                <td mat-cell [ngClass]="{'no-teacher': user.role !== UserRoles.Teacher, 'teacher': user.role === UserRoles.Teacher}" *matCellDef="let element; let i = index" #td>
                    <div class="actions-container">
                        <button mat-button (click)="showProfile(students.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'student-dashboard-table.button.showProfile' | translate }}</button>
                    </div>
                </td>
            </ng-container>

            <ng-container *ngIf="user.role === UserRoles.Teacher" matColumnDef="online" stickyEnd>
                <th mat-header-cell [ngClass]="{'no-teacher': user.role !== UserRoles.Teacher, 'teacher': user.role === UserRoles.Teacher}" *matHeaderCellDef></th>
                <td mat-cell [ngClass]="{'no-teacher': user.role !== UserRoles.Teacher, 'teacher': user.role === UserRoles.Teacher}" *matCellDef="let element; let i = index" #td>
                    <div class="actions-container">
                        <button mat-button [disabled]="!students.list[!this.currentIndex ? i : this.currentIndex + i]?.lesson?.targetTeacher" (click)="teachOnline(students.list[!this.currentIndex ? i : this.currentIndex + i])">{{ 'notify-lesson.btn-teach' | translate }}</button>
                    </div>
                </td>
            </ng-container>


            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </div>

<!--MOBILE-->
     <div class="mobile-table" *ngIf="students?.list && students.list.length > 0 && isMobilePlatform; else emptyMobileContainer">
         <div class="toolbar-wrapper">
             <mat-toolbar class="toolbar-form">
                 <div class="left-side">
                     <button mat-icon-button class="example-icon" (click)="closeDialog()">
                         <mat-icon class="close-icon">chevron_left</mat-icon>
                     </button>
                     <span>{{ 'statistics.students' | translate }}</span>
                 </div>
                 <div class="more-container">
                     <button mat-icon-button [matMenuTriggerFor]="menu">
                         <mat-icon>more_vert</mat-icon>
                     </button>
                     <mat-menu #menu="matMenu">
                         <button [disabled]="true" mat-menu-item
                                 (click)="addOrEditStudent()">{{ 'student-dashboard-table.button.addStudent' | translate }}
                         </button>
                         <mat-divider></mat-divider>
                         <button [disabled]="true" style="opacity: 0.3"
                                 mat-menu-item>{{ 'student-dashboard-table.button.copy-invite-link' | translate }}
                         </button>
                     </mat-menu>
                 </div>
             </mat-toolbar>
             <mat-divider></mat-divider>
         </div>
    <mat-list role="list">
        <ng-container *ngFor="let item of paginatedData; let i = index">
            <a mat-list-item matRipple (click)="showProfile(students.list[!this.currentIndex ? i : this.currentIndex + i])">
                <div class="list-wrapper">
                    <div class="logo-container">
                        <img
                            src="{{ item.files?.[0]?.url ? item.files?.[0]?.url : '/assets/images/profile/unknown.jpg' }}"
                            alt="Logo" class="logo">
                    </div>
                    <div class="text-container">
                        <span class="name">{{ item?.firstName }} {{ item?.lastName ? item?.lastName : ''}}</span>
                        <ng-container *ngIf="item.student?.instruments">
                      <span class="instrument">
                          <ng-container *ngFor="let instrument of item.student?.instruments; let last = last">
                                           <ng-container *ngIf="isMusicEntity(instrument)">{{ currentLanguage === 'eng' ? instrument?.nameEn : instrument?.nameDe }}</ng-container>
                              <span *ngIf="!last">, </span>
                        </ng-container>
                     </span>
                        </ng-container>
                        <span *ngIf="!item.student?.instruments || item.student?.instruments?.length === 0">—</span>
                    </div>
                </div>
            </a>
            <mat-divider></mat-divider>
        </ng-container>
    </mat-list>
</div>

<ng-template #emptyDesktopContainer>
    <div *ngIf="!isMobilePlatform" class="empty-container">
        {{ 'student-dashboard-table.message.noDataAvailable' | translate }}
    </div>
</ng-template>

<ng-template #emptyMobileContainer>
    <ng-container *ngIf="isMobilePlatform">
        <div class="toolbar-wrapper">
            <mat-toolbar class="toolbar-form">
                <div class="left-side">
                    <button mat-icon-button class="example-icon" (click)="closeDialog()">
                        <mat-icon class="close-icon">chevron_left</mat-icon>
                    </button>
                    <span>{{ 'statistics.students' | translate }}</span>
                </div>
                <div class="more-container">
                    <button [disabled]="true" mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                    </mat-menu>
                </div>
            </mat-toolbar>
            <mat-divider></mat-divider>
        </div>
    <div class="empty-container">
        {{ 'student-dashboard-table.message.noDataAvailable' | translate }}
    </div>
    </ng-container>
</ng-template>

<!--DESKTOP-->
<mat-paginator *ngIf="!isMobilePlatform"  [ngClass]="{'empty-paginator': dataSource && dataSource.data && dataSource.data.length < 10, 'hide-empty-dashboard-line': students?.list?.length === 0 }" [pageSizeOptions]="[10, 20, 30]"
               showFirstLastButtons
               (page)="onPaginatorPageChange($event)">
</mat-paginator>

<!--MOBILE-->
<div *ngIf="isMobilePlatform && students?.list && students.list.length > 0" class="paginator-wrapper">
    <mat-paginator
        [pageSize]="10"
        (page)="onPageChange($event)">
    </mat-paginator>
</div>


