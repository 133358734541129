import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    Inject,
    OnInit,
    QueryList,
    ViewChild,
    ViewChildren
} from '@angular/core';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormControl,
    FormGroup,
    ValidationErrors,
    Validators
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSelectChange } from '@angular/material/select';
import { EMAIL_VALIDATION_REG, LANGUAGES, SINGLE_GENDER } from '../../../../shared/constants/form-option';
import { PriceItem, SchoolItem, SocialsItem, UnionsItem, UsersFormBody } from '../../../services/api/api.type';
import { debounceTime, filter, Subject, switchMap, takeUntil } from 'rxjs';
import { ApiService } from '../../../services/api/api.service';
import { DeleteConfirmComponent } from '../../dashboard/delete-confirm/delete-confirm.component';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '../../../services/platform.service';
import { ComponentType } from '@angular/cdk/overlay';

@Component({
  selector: 'app-organization-form',
  templateUrl: './organization-form.component.html',
  styleUrls: ['./organization-form.component.scss']
})
export class OrganizationFormComponent implements OnInit {
    organisationProfileForm: FormGroup;
    originalFormValues: typeof this.organisationProfileForm.value;
    loading = false;
    @ViewChildren('customInput') inputs: QueryList<ElementRef>;
    @ViewChild('matchEmailInput') matchEmailInput!: ElementRef;

    get associationsGroup(): any {
        return this.organisationProfileForm.get('associationsGroup') as FormArray;
    }
    get socialMediaGroup(): any {
        return this.organisationProfileForm.get('socialMediaGroup') as FormArray;
    }

    get priceRangeGroup(): any {
        return this.organisationProfileForm.get('priceRangeGroup') as FormArray;
    }

    get email() {
        return this.organisationProfileForm.get('email');
    }

    get matchEmail() {
        return this.organisationProfileForm.get('matchEmail');
    }

    get matchNotification() {
        return this.organisationProfileForm.get('matchNotification');
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    selectOptions = [
        { label: 'Instagram', value: 'Instagram' },
        { label: 'Facebook', value: 'Facebook' },
        { label: 'X', value: 'X' },
        { label: 'TikTok', value: 'TikTok' },
        { label: 'YouTube', value: 'YouTube' },
        { label: 'LinkedIn', value: 'LinkedIn' },
        { label: 'Custom', value: 'custom' }
    ];

    unitOptions = [
        { label: '30 min', value: '30m' },
        { label: '45 min', value: '45m' },
        { label: '60 min', value: '60m' },
    ];

    matchNotificationOptions = [
        { value: 'send_to_teacher' },
        { value: 'send_to_school' },
        { value: 'send_to_another_email' },
        { value: 'send_to_school_and_teacher'}
    ];

    selectGender = SINGLE_GENDER;

    languageOptions = LANGUAGES;
    private destroy$: Subject<void> = new Subject<void>();


    constructor(
        private fb: FormBuilder,
        public dialogRef: MatDialogRef<OrganizationFormComponent>,
        @Inject(MAT_DIALOG_DATA) public data: SchoolItem,
        private dialog: MatDialog,
        private cd: ChangeDetectorRef,
        private apiService: ApiService,
        private translateService: TranslateService,
        private elementRef: ElementRef,
        private platformService: PlatformService) {}

    ngOnInit(): void {
        this.organisationProfileForm = this.fb.group({
            name: [this.data?.name, Validators.required],
            firstName: [this.data?.firstName, !this.data ? [Validators.required] : []],
            sponsoringCompany: [this.data?.sponsor ? this.data?.sponsor: ''],
            postalCode: [this.data?.postalCode?.toString(), Validators.required],
            phoneNumber: [this.data?.phoneNumber, Validators.required],
            description: [this.data?.description],
            lastName: [this.data?.lastName, !this.data ? [Validators.required] : []],
            associationsGroup: this.fb.array([]),
            socialMediaGroup: this.fb.array([]),
            priceRangeGroup: this.fb.array([]),
            email: [this.data?.email, !this.data ? [Validators.required, Validators.pattern(EMAIL_VALIDATION_REG)]: []],
            gender: [this.data?.gender],
            language: [this.data?.language, !this.data ? Validators.required : []],
            // price: [this.data?.priceRange?.price ? this.data?.priceRange?.price + '€': null, !this.data ? Validators.required: []],
            // unit: [this.data?.priceRange?.length, !this.data ? Validators.required : []],
            matchNotification: [this.data?.matchNotification, !this.data ? Validators.required : []],
            matchEmail: [this.data?.matchNotificationEmails ? this.data?.matchNotificationEmails[0] : this.data?.matchNotificationEmails, !this.data ? [Validators.required, Validators.pattern(EMAIL_VALIDATION_REG)]: []],
            website: [this.data?.website],
        });
        this.addAssociationsControls(!!this.data, this.data?.unions);
        this.addSocialMediaControls(!!this.data, this.data?.socials);
        this.addPriceRangeControls(!!this.data, this.data?.priceRange);
        this.originalFormValues = { ...this.organisationProfileForm.value };

        this.email?.valueChanges.pipe(
            debounceTime(300),
            takeUntil(this.destroy$)
        ).subscribe((value) => {
            this.checkIfUserExist(value);
        });
        if (this.matchNotification?.value !== 'send_to_another_email') {
            this.matchNotification
            this.matchEmail?.disable();
        }

        this.matchNotification?.valueChanges.pipe(takeUntil(this.destroy$)).subscribe((value) => {
            if (value !== 'send_to_another_email') {
                this.matchEmail?.disable();
                this.matchEmail?.setValue(null);
            } else {
                this.matchEmail?.enable();
                this.setFocusOnMatchEmailInput();
                if (this.data?.matchNotificationEmails) {
                    this.matchEmail?.setValue(this.data?.matchNotificationEmails);
                }
            }
        });
    }

    checkIfUserExist(email: string) {
        if (this.email?.valid) {
            if (this.data?.email && email === this.data?.email) {
                this.email?.setErrors(null);
                return;
            }
            this.apiService.checkIfUserExist(email).subscribe(
                () => {
                    this.email?.setErrors(null);
                },
                () => {
                    this.email?.setErrors({ alreadyExist: true });
                    this.cd.detectChanges();
                }
            );
        }
    }

    getFieldProperties<T>(fieldName: string, controlField?:AbstractControl<T> | null): {
        control: AbstractControl<any> | null;
        hasError: boolean;
        isRequired: boolean;
        hasAllErrors: boolean;
    } {
        let control: AbstractControl<any> | null;
        if (controlField) {
            control = controlField.get(fieldName);
        } else {
            control = this.organisationProfileForm.get(fieldName);
        }
        const isRequired = !!((control?.hasError('required') && control?.dirty) || (control?.hasError('required') && control?.touched));
        const hasError = !!((control?.errors && !control?.hasError('required') && control?.dirty) || (control?.errors && !control?.hasError('required') && control?.touched));
        const hasAllErrors = isRequired || hasError;
        return { control, isRequired, hasError, hasAllErrors } ;
    }

    addAssociationsControls(defaultValue?: boolean, unions: UnionsItem[] = []) {
        if (defaultValue && unions && unions.length > 0) {
            unions?.forEach(union => {
                const control = this.fb.group({
                    name: [ union.name, this.crossFieldValidator.bind(this) ],
                    membership: [ union.membership, this.crossFieldValidator.bind(this) ]
                });
                this.associationsGroup.push(control);
            });
        } else {
            const control = this.fb.group({
                name: [ '', this.crossFieldValidator.bind(this) ],
                membership: [ '', this.crossFieldValidator.bind(this) ]
            });
            this.associationsGroup.push(control);
        }
    }

    addPriceRangeControls(defaultValue?: boolean, items: PriceItem[] = []) {
        if (defaultValue && items && items.length > 0) {
            items?.forEach(item => {
                const control = this.fb.group({
                    price: [item.price + '€', Validators.required],
                    length: [item.length, Validators.required]
                });
                control.get('price')?.valueChanges.pipe(
                    filter(_ => !control.get('price')?.pending),
                    takeUntil(this.destroy$)
                ).subscribe((val) => {
                    if (val) {
                        val = val.replace(/€/g, '');
                        val = val + '€';
                        control.get('price')?.setValue(val, {emitEvent: false});
                    } else {
                        control.get('price')?.setValue(null);
                    }
                });
                control.get('length')?.valueChanges.pipe(
                    filter(_ => !control.get('length')?.pending),
                    takeUntil(this.destroy$)
                ).subscribe((val) => {
                    if (val) {
                        let numericPart = val.replace(/[^\d]/g, '');
                        if (numericPart) {
                            let num = parseInt(numericPart, 10);
                            if (num < 1) {
                                num = 1;
                            } else if (num > 60) {
                                num = 60;
                            }
                            val = num + ' min';
                        } else {
                            val = '';
                        }
                        control.get('length')?.setValue(val, { emitEvent: false });
                    } else {
                        control.get('length')?.setValue(null);
                    }
                });
                this.priceRangeGroup.push(control);
            });
        } else {
            const control = this.fb.group({
                price: ['', Validators.required],
                length: ['', Validators.required]
            });
            control.get('price')?.valueChanges.pipe(
                filter(_ => !control.get('price')?.pending),
                takeUntil(this.destroy$)
            ).subscribe((val) => {
                if (val) {
                    val = val.replace(/€/g, '');
                    val = val + '€';
                    control.get('price')?.setValue(val, {emitEvent: false});
                } else {
                    control.get('price')?.setValue(null);
                }
            });
            control.get('length')?.valueChanges.pipe(
                filter(_ => !control.get('length')?.pending),
                takeUntil(this.destroy$)
            ).subscribe((val) => {
                if (val) {
                    let numericPart = val.replace(/[^\d]/g, '');
                    if (numericPart) {
                        let num = parseInt(numericPart, 10);
                        if (num < 1) {
                            num = 1;
                        } else if (num > 60) {
                            num = 60;
                        }
                        val = num + ' min';
                    } else {
                        val = '';
                    }
                    control.get('length')?.setValue(val, { emitEvent: false });
                } else {
                    control.get('length')?.setValue(null);
                }
            });
            this.priceRangeGroup.push(control);
        }

    }

    removePriceRange(index: number) {
        this.priceRangeGroup.removeAt(index);
    }

    scrollToErrorField(): void {
        const firstErrorField = this.elementRef.nativeElement.querySelector('mat-form-field.ng-invalid');
        if (firstErrorField) {
            const parentElement = firstErrorField.parentElement;
            parentElement.scrollIntoView({behavior: 'smooth', block: 'start'});
        }
    }

    addSocialMediaControls(defaultValue?: boolean, socials: SocialsItem[] = []) {
        if (defaultValue && socials && socials.length > 0) {
            const selectOptions = this.selectOptions.map((option) => option.value)
            socials?.forEach(social => {
                let socialType: string = '';
                let hasCustom = false;
                if (selectOptions.includes(social.type)) {
                    socialType = social.type;
                } else if (social.type) {
                    hasCustom = true;
                    socialType = social.type;
                }
                const control = this.fb.group({
                    socialMedia: [ socialType, this.crossFieldSocialMediaValidator.bind(this) ],
                    link: [ social.link, this.crossFieldSocialMediaValidator.bind(this) ],
                    custom: [ hasCustom,  Validators.required ]
                });
                this.socialMediaGroup.push(control);
            })
        } else {
            const control = this.fb.group({
                socialMedia: [ '', this.crossFieldSocialMediaValidator.bind(this) ],
                link: [ '', this.crossFieldSocialMediaValidator.bind(this) ],
                custom: [ false,  Validators.required ]
            });
            this.socialMediaGroup.push(control);
        }
    }

    removeAssociations(index: number) {
        this.associationsGroup.removeAt(index);
    }

    removeSocial(index: number) {
        this.socialMediaGroup.removeAt(index);
    }
    onSubmit() {
        this.organisationProfileForm.markAllAsTouched();
        if (this.organisationProfileForm.invalid) {
            this.scrollToErrorField();
            return;
        }
        this.loading = true;

        const userBody: UsersFormBody = {
            language: this.organisationProfileForm.get('language')?.value,
            role: 'school_admin',
            email: this.organisationProfileForm.get('email')?.value,
            firstName: this.organisationProfileForm.get('firstName')?.value,
            lastName: this.organisationProfileForm.get('lastName')?.value,
            gender: this.organisationProfileForm.get('gender')?.value,
            postalCode: this.organisationProfileForm.get('postalCode')?.value ? this.organisationProfileForm.get('postalCode')?.value : null,
            phoneNumber: this.organisationProfileForm.get('phoneNumber')?.value ? this.organisationProfileForm.get('phoneNumber')?.value : null,
        }

        if (this.data?.hasEditMyProfile) {
            this.editOrganisationProfile(userBody);
            return;
        }
        this.data ? this.editOrganisation() : this.addOrganisation(userBody);
    }

    editOrganisationProfile(userBody: UsersFormBody) {
        const socials: SocialsItem[] = this.socialMediaGroup.value.map((social: {
            socialMedia: string;
            link: string;
        }) => ({ type: social?.socialMedia, link: social?.link }));

        const filteredSocials = socials.filter((item : {
            type: string;
            link: string;
        }) => {
            return Object.values(item).every(value => value !== '');
        });

        const filteredAssociationsGroup = this.associationsGroup.value.filter((item: { name: string; membership: string; }) => {
            return item.name.trim() !== '';
        });

        const priceRange = this.priceRangeGroup.value.map((item: {
            price: string;
            length: string;
        }) => {
            const numericPriceString = item.price.replace(/[^\d.,€]/g, '');
            const numericPrice = parseFloat(numericPriceString.replace(',', '.'));
            return { price: numericPrice, length: item.length }
        });
        const editOrganisationFormData = {
            name: this.organisationProfileForm.get('name')?.value,
            unions: filteredAssociationsGroup.length > 0 ? filteredAssociationsGroup : null,
            socials: filteredSocials.length > 0 ? filteredSocials : null,
            description: this.organisationProfileForm.get('description')?.value,
            sponsor: this.organisationProfileForm.get('sponsoringCompany')?.value ? this.organisationProfileForm.get('sponsoringCompany')?.value : null,
            postalCode: this.organisationProfileForm.get('postalCode')?.value ? this.organisationProfileForm.get('postalCode')?.value : null,
            phoneNumber: this.organisationProfileForm.get('phoneNumber')?.value ? this.organisationProfileForm.get('phoneNumber')?.value : null,
            website: this.organisationProfileForm.get('website')?.value ? this.organisationProfileForm.get('website')?.value : null,
            matchNotification: this.organisationProfileForm.get('matchNotification')?.value,
            matchNotificationEmails: this.matchEmail?.value ? [this.matchEmail?.value] : null,
            priceRange,
            language: this.organisationProfileForm.get('language')?.value,
        }
        this.apiService.editeOrganisation(editOrganisationFormData, this.data.id).pipe(switchMap(() => {
            return this.apiService.editUser(userBody, this.data.adminId);
        })).subscribe(() => {
            this.loading = false;
            this.closeDialog(true);
        }, err => {
            this.loading = false;
        });
    }

    editOrganisation() {
        const socials: SocialsItem[] = this.socialMediaGroup.value.map((social: {
            socialMedia: string;
            link: string;
        }) => ({ type: social?.socialMedia, link: social?.link }));

        const filteredSocials = socials.filter((item : {
            type: string;
            link: string;
        }) => {
            return Object.values(item).every(value => value !== '');
        });

        const filteredAssociationsGroup = this.associationsGroup.value.filter((item: { name: string; membership: string; }) => {
            return item.name.trim() !== '';
        });
        const priceRange = this.priceRangeGroup.value.map((item: {
            price: string;
            length: string;
        }) => {
            const numericPriceString = item.price.replace(/[^\d.,€]/g, '');
            const numericPrice = parseFloat(numericPriceString.replace(',', '.'));
            return { price: numericPrice, length: item.length }
        });
        const editOrganisationFormData = {
            name: this.organisationProfileForm.get('name')?.value,
            unions: filteredAssociationsGroup.length > 0 ? filteredAssociationsGroup : null,
            socials: filteredSocials.length > 0 ? filteredSocials : null,
            description: this.organisationProfileForm.get('description')?.value,
            sponsor: this.organisationProfileForm.get('sponsoringCompany')?.value ? this.organisationProfileForm.get('sponsoringCompany')?.value : null,
            postalCode: this.organisationProfileForm.get('postalCode')?.value ? this.organisationProfileForm.get('postalCode')?.value : null,
            phoneNumber: this.organisationProfileForm.get('phoneNumber')?.value ? this.organisationProfileForm.get('phoneNumber')?.value : null,
            website: this.organisationProfileForm.get('website')?.value ? this.organisationProfileForm.get('website')?.value : null,
            matchNotification: this.organisationProfileForm.get('matchNotification')?.value,
            matchNotificationEmails: this.matchEmail?.value ? [this.matchEmail?.value] : null,
            priceRange,
        }

        this.apiService.editeOrganisation(editOrganisationFormData, this.data.id).subscribe(() => {
            this.loading = false;
            this.closeDialog(true);
        }, err => {
            this.loading = false;
        });

    }

    addOrganisation(userBody: UsersFormBody) {
        const socials: SocialsItem[] = this.socialMediaGroup.value.map((social: {
            socialMedia: string;
            link: string;
        }) => ({type: social?.socialMedia, link: social?.link }));

        const filteredSocials = socials.filter((item : {
            type: string;
            link: string;
        }) => {
            return Object.values(item).every(value => value !== '');
        });

        const filteredAssociationsGroup = this.associationsGroup.value.filter((item: { name: string; membership: string; }) => {
            return item.name.trim() !== '';
        });

        const priceRange = this.priceRangeGroup.value.map((item: {
            price: string;
            length: string;
        }) => {
            const numericPriceString = item.price.replace(/[^\d.,€]/g, '');
            const numericPrice = parseFloat(numericPriceString.replace(',', '.'));
            return { price: numericPrice, length: item.length }
        });

        const organisationFormData = {
            name: this.organisationProfileForm.get('name')?.value,
            language: this.organisationProfileForm.get('language')?.value,
            unions: filteredAssociationsGroup.length > 0 ? filteredAssociationsGroup : null,
            socials: filteredSocials.length > 0 ? filteredSocials : null,
            description: this.organisationProfileForm.get('description')?.value,
            sponsor: this.organisationProfileForm.get('sponsoringCompany')?.value ? this.organisationProfileForm.get('sponsoringCompany')?.value : null,
            postalCode: this.organisationProfileForm.get('postalCode')?.value ? this.organisationProfileForm.get('postalCode')?.value : null,
            phoneNumber: this.organisationProfileForm.get('phoneNumber')?.value ? this.organisationProfileForm.get('phoneNumber')?.value : null,
            website: this.organisationProfileForm.get('website')?.value ? this.organisationProfileForm.get('website')?.value : null,
            matchNotification: this.organisationProfileForm.get('matchNotification')?.value,
            matchNotificationEmails: this.matchEmail?.value ? [this.matchEmail?.value] : null,
            priceRange,
        }
       this.apiService.createOrganisation(organisationFormData).pipe(
           switchMap((org) => {
               userBody.manageSchoolId = org.id;
               return this.apiService.createUser(userBody);
           })
       ).subscribe(() => {
               this.loading = false;
               this.closeDialog(true);
       },err => {
               this.loading = false;
           });
    }

    discard() {
        this.organisationProfileForm.reset(this.originalFormValues);
    }
    closeDialog(update = false) {
        this.destroy$.next();
        this.destroy$.complete();
        this.dialogRef.close(update);
    }

    onSelectSocialMedia(event: MatSelectChange, control: FormControl, index: number) {
        if (event.value === 'custom') {
            control.get('socialMedia')?.setValue('');
            control.get('custom')?.setValue(true);
            this.cd.detectChanges();
            const input = this.inputs.toArray().find(input => input.nativeElement.id === `custom-${index}`);
            input?.nativeElement.focus();
        }
    }

    clearInput(control: FormControl) {
        control.get('socialMedia')?.setValue('');
        control.get('custom')?.setValue(false);
    }

    deleteOrganisation(key: string) {
        const title = this.translateService.instant(key);
        const type = this.translateService.instant('confirm_delete.organisation_type');
        const description = this.translateService.instant('confirm_delete.org_description');
       this.openDeleteConfirmDialog(DeleteConfirmComponent, 'delete-confirm', { id: this.data.id, title, name: this.data.name, type, description });
    }

    openDeleteConfirmDialog<T>(component: ComponentType<T>, panelClass: string, dialogData?: {
        id: string;
        title: string;
        name: string;
        type: string;
        description?: string;
    }): void {
        document.body.classList.add('modal-open');
        const dialogRef = this.dialog.open(component, {
            panelClass: panelClass,
            hasBackdrop: true,
            autoFocus: false,
            width: '480px',
            disableClose: true,
            data: dialogData
        });

        dialogRef.afterClosed().subscribe(schoolId => {
            document.body.classList.remove('modal-open');
            if (schoolId) {
               this.apiService.deleteOrganisation(schoolId).subscribe(() => {
                   this.dialogRef.close({delete: true});
               });
            }
            console.log('Dialog closed result', schoolId);
        });
    }

    crossFieldValidator(control: AbstractControl): ValidationErrors | null {
        const name = control?.parent?.get('name');
        const membership = control?.parent?.get('membership');

        if (membership?.value && !name?.value) {
            name?.setErrors({ requiredName: true });
            name?.markAsTouched();
            return { requiredName: true };
        }

        name?.setErrors(null);
        return null;
    }

    crossFieldSocialMediaValidator(control: AbstractControl): ValidationErrors | null {
        const socialMediaValue = control?.parent?.get('socialMedia');
        const linkValue = control?.parent?.get('link');

        if (socialMediaValue?.value && !linkValue?.value) {
            linkValue?.setErrors({ requiredLink: true });
            linkValue?.markAsTouched();
            return { requiredLink: true };
        }

        if (linkValue?.value && !socialMediaValue?.value) {
            socialMediaValue?.setErrors({ requiredSocialMedia: true });
            socialMediaValue?.markAsTouched();
            return { requiredSocialMedia: true };
        }

        socialMediaValue?.setErrors(null);
        linkValue?.setErrors(null);
        return null;
    }

    private setFocusOnMatchEmailInput(): void {
        setTimeout(() => {
            if (this.matchEmailInput) {
                (this.matchEmailInput.nativeElement as HTMLInputElement).focus();
            }
        })
    }
}
