import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PlatformService {
    private _isMobile = false;
    private resizeObserver: any;

    private isMobileSubject = new BehaviorSubject<boolean>(this._isMobile);

    constructor() {
        this._isMobile = this.checkIsMobile();
        this.isMobileSubject.next(this._isMobile);

        this.resizeObserver = () => {
            this._isMobile = this.checkIsMobile();
            this.isMobileSubject.next(this._isMobile);
        };
        window.addEventListener('resize', this.resizeObserver);
    }

    ngOnDestroy(): void {
        window.removeEventListener('resize', this.resizeObserver);
    }

    private checkIsMobile(): boolean {
        return window.innerWidth <= 1024;
    }

    get isMobile(): boolean {
        return this._isMobile;
    }
}
