<app-notify-lesson
    *ngIf="studentNotifyData?.earliestLesson"
    [date]="studentNotifyData?.earliestLesson"
    [role]="role"
    [target]="studentNotifyData?.teacher?.lesson?.targetStudent"
    [avatar]="teacherFileUrl"
    [description]="'next_piano_lesson' | translate: { firstName: studentNotifyData?.teacher?.firstName }">
</app-notify-lesson>

<div class="statistics-container">
    <app-statistics
        [role]="role"
        [rootIntroInfo]="userIntroInfo"
        (openTeachersList)="openTeacherList()">
    </app-statistics>
</div>

