import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { forkJoin, map, Observable, of, switchMap, tap } from 'rxjs';
import {
    Avatar, Genre, Instrument, IntegrationListResponse, IntegrationTeacherListResponse, Matches, MatchesListResponse,
    MatchingResponse,
    OrganisationBody, ScheduleItem,
    SchedulesBody, SchoolItem, SchoolListResponse, Student,
    StudentFormBody, StudentsResponse, Teacher,
    TeacherFormBody, TeachersResponse,
    UpdateMatchingBody,
    UserResponse,
    UsersFormBody,
    UserStudent,
    UserTeacher
} from './api.type';
import { environment } from '../../../../environments/environment';
import { UserService } from '../user/user.service';
import { UserRoles } from '../../../core/models/user-info.interface';
import { MusicEntity, MusicService } from '../../../shared/services/music.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

    private apiUrl = environment.apiUrl;
    private httpOptions = {
        headers: new HttpHeaders({'Content-Type': 'application/json'}),
    };

    constructor(private httpClient: HttpClient, private userService: UserService, private musicService: MusicService) {
    }

    getUser(userId: string): Observable<UserResponse> {
        return this.httpClient.get<UserResponse>(`${this.apiUrl}/users/${userId}`, this.httpOptions);
    }

    updateUserConfirmation(userId: string, key: string, role: string): Observable<boolean> {
        return this.httpClient.put<boolean>(`${this.apiUrl}/users/${userId}/${key}?role=${role}`, this.httpOptions);
    }

    getTeacher(teacherId: string): Observable<Teacher> {
        return this.httpClient.get<Teacher>(`${this.apiUrl}/teachers/${teacherId}`, this.httpOptions).pipe(
            map((teacher: Teacher) => {
                const result = this.mapProfileInstrumentsAndGenres(teacher);
                return result as Teacher;
            })
        );
    }

    getStudent(studentId: string): Observable<Student> {
        return this.httpClient.get<Student>(`${this.apiUrl}/students/${studentId}`, this.httpOptions).pipe(
            map((student: Student) => {
                const result = this.mapProfileInstrumentsAndGenres(student);
                return result as Student;
            })
        );
    }

    getTimeOverlaps(teacherId: string, studentId: string): Observable<string[]> {
        return this.httpClient.get<string[]>(`${this.apiUrl}/schedules/${teacherId}/${studentId}`);
    }

    getMatching(teacherId: string, studentId: string): Observable<MatchingResponse> {
        return this.httpClient.get<MatchingResponse>(`${this.apiUrl}/matches/match/${teacherId}/${studentId}`)
    }

    requestStudentMatching(studentId: string, body: {
        instruments: (string | MusicEntity | null)[] | undefined;
        genres: (string | MusicEntity | null)[] | undefined
    }): Observable<Teacher[]> {
        return this.httpClient.post<Teacher[]>(`${this.apiUrl}/matches/${studentId}`, body, this.httpOptions).pipe(
            map((teacher) => {
                return this.mapMatchesTeacherInstrumentsAndGenres(teacher);
            })
        );
    }

    updateMatching(body: UpdateMatchingBody): Observable<object> {
        return this.httpClient.put(`${this.apiUrl}/matches`, body, this.httpOptions);
    }

    createUser(body: UsersFormBody): Observable<any> {
        body.timezone = 'Europe/Berlin';
        return this.httpClient.post(`${this.apiUrl}/users`, body, this.httpOptions);
    }

    editUser(body: UsersFormBody, userId: string, updateUserIntroInfo = false): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/users/${userId}`, body, this.httpOptions).pipe(
            tap((user: any) => {
                if (updateUserIntroInfo) {
                    this.userService.userIntroInfo['userFirstName'] = user.firstName;
                }
            })
        );
    }

    editTeacher(body: TeacherFormBody, teacherId: string): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/teachers/${teacherId}`, body, this.httpOptions);
    }

    createStudent(body: StudentFormBody): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}/students`, body, this.httpOptions);
    }

    editStudent(body: StudentFormBody, studentId: string): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/students/${studentId}`, body, this.httpOptions);
    }

    persistSchedule(body: SchedulesBody[], userId: string): Observable<any> {
        const bodyData = {
            schedules: body,
        }
        return this.httpClient.post(`${this.apiUrl}/schedules/${userId}`, bodyData, this.httpOptions);
    }

    getSchedules(userId: string): Observable<any> {
        return this.httpClient.get(`${this.apiUrl}/schedules/${userId}`, this.httpOptions);
    }

    getOverlappingTimeSlots(teacherId: string, studentId: string, duration: string): Observable<string[]> {
        const currentTime: Date = new Date();
        const oneDay = 24 * 60 * 60 * 1000;

        const twentyFourHoursAgo: Date = new Date(
            currentTime.getTime() - oneDay
        );
        const startTime: string = twentyFourHoursAgo.toISOString();
        const todaysDate = new Date();
        todaysDate.setDate(todaysDate.getDate() + 6)
        const endTime = todaysDate.toISOString();
        return this.httpClient.get<string[]>(`${this.apiUrl}/schedules/${teacherId}/slots?startTime=${startTime}&endTime=${endTime}&duration=${duration}m`, this.httpOptions);
    }


    /* getOverlappingTimeSlots(teacherId: string, studentId: string, duration: string) {
        const currentTime: Date = new Date();
        const oneDay = 24 * 60 * 60 * 1000;

        const twentyFourHoursAgo: Date = new Date(
            currentTime.getTime() - oneDay
        );
        const startTime: string = twentyFourHoursAgo.toISOString();
        const todaysDate = new Date();
        todaysDate.setDate(todaysDate.getDate() + 6)
        const endTime = todaysDate.toISOString();
        return this.httpClient.get(`${this.apiUrl}/schedules/${teacherId}/${studentId}?duration=${duration}&startTime=${startTime}&endTime=${endTime}`, this.httpOptions);
    } */

    createTeacher(body: TeacherFormBody): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}/teachers`, body, this.httpOptions);
    }

    updateTeacherValidation(teacherId: string, validationBody: { validated: boolean }): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/teachers/${teacherId}/validation`, validationBody, this.httpOptions);
    }

    updateSchoolValidation(schoolId: string, validationBody: { validated: boolean }): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/schools/${schoolId}/validation`, validationBody, this.httpOptions);
    }

    getLessonsList(userId: string): Observable<any> {
        const currentTime: Date = new Date();
        const oneDay = 24 * 60 * 60 * 1000;

        const twentyFourHoursAgo: Date = new Date(
            currentTime.getTime() - oneDay
        );
        const startTime: string = twentyFourHoursAgo.toISOString();
        const todaysDate = new Date();
        todaysDate.setDate(todaysDate.getDate() + 30)
        const endTime = todaysDate.toISOString();
        return this.httpClient.get(`${this.apiUrl}/lessons/${userId}/lessons?page=${1}&limit=${100}&startTime=${startTime}&endTime=${endTime}`, this.httpOptions);
    }

    createLesson(data: any, userId: string) {
        return this.httpClient.post(`${this.apiUrl}/lessons/${userId}`, data, this.httpOptions);
    }

    getOrganisation(organisationId?: string): Observable<SchoolItem> {
        return this.httpClient.get<SchoolItem>(`${this.apiUrl}/schools/${organisationId}`, this.httpOptions);
    }

    getMatchesList(organisationId: string, page: string, limit: string, status: string): Observable<any> {
        return this.httpClient.get<MatchesListResponse>(`${this.apiUrl}/matches/list?schoolId=${organisationId}&status=${status}&page=${page}&limit=${limit}`, this.httpOptions).pipe(
            map((matches) => {
                return this.mapInstrumentsAndGenres(matches)
            })
        );
    }

    getSortedMatches(organisationId: string, page: string, limit: string, status: string, sortBy: string, direction: string): Observable<any> {
        return this.httpClient.get(`${this.apiUrl}/matches/list?schoolId=${organisationId}&status=${status}&page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions);
    }

    createOrganisation(data: OrganisationBody): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}/schools`, data, this.httpOptions);
    }

    editeOrganisation(body: OrganisationBody, organisationId: string): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/schools/${organisationId}`, body, this.httpOptions);
    }

    getOrganisations(page: string, limit: string): Observable<SchoolListResponse> {
        return this.httpClient.get<SchoolListResponse>(`${this.apiUrl}/schools/?page=${page}&limit=${limit}`, this.httpOptions).pipe(
            tap((organisations: any) => {
                this.userService.userIntroInfo['organisationAmount'] = organisations.count;
            })
        );
    }

    getSortedOrganisations(page: string, limit: string, sortBy: string, direction: string): Observable<SchoolListResponse> {
        return this.httpClient.get<SchoolListResponse>(`${this.apiUrl}/schools/?page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions).pipe(
            tap((organisations: any) => {
                this.userService.userIntroInfo['organisationAmount'] = organisations.count;
            })
        );
    }

    getSortedTeachers(page: string, limit: string, sortBy: string, direction: string): Observable<TeachersResponse> {
        if (this.userService.userInfo.role === UserRoles.Organisation && this.userService.userInfo.manageSchoolId) {
            const schoolId = this.userService.userInfo.manageSchoolId;
            return this.httpClient.get<TeachersResponse>(`${this.apiUrl}/teachers/list/?page=${page}&limit=${limit}&schoolId=${schoolId}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions).pipe(
                map((teachers: TeachersResponse) => {
                    this.userService.userIntroInfo['teachersAmount'] = teachers.count;
                    return this.mapTeacherInstrumentsAndGenres(teachers);
                })
            );
        }
        if (this.userService.userInfo.role === UserRoles.Student) {
            const studentId = this.userService.userInfo.studentId;
            return this.httpClient.get(`${this.apiUrl}/matches/student/${studentId}?page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions).pipe(
                map((response: any) => ({
                    list: response.list.map((item: any) => ({
                        ...item.teacherUser,
                        acceptedStudent: item.acceptedStudent,
                        acceptedTeacher: item.acceptedTeacher
                    })),
                    pages: response.pages,
                    page: response.page,
                    count: response.count
                })),
                map((teachers: TeachersResponse) => {
                    this.userService.userIntroInfo['teachersAmount'] = teachers.count;
                    return this.mapTeacherInstrumentsAndGenres(teachers);
                })
            );
        }
        return this.httpClient.get<TeachersResponse>(`${this.apiUrl}/teachers/list/?page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions).pipe(
            map((teachers) => {
                this.userService.userIntroInfo['teachersAmount'] = teachers.count;
                return this.mapTeacherInstrumentsAndGenres(teachers);
            })
        );
    }

    getTeachers(page: string, limit: string): Observable<TeachersResponse> {
        if (this.userService.userInfo.role === UserRoles.Organisation && this.userService.userInfo.manageSchoolId) {
            const schoolId = this.userService.userInfo.manageSchoolId;
            return this.httpClient.get<TeachersResponse>(`${this.apiUrl}/teachers/list/?page=${page}&limit=${limit}&schoolId=${schoolId}`, this.httpOptions).pipe(
                map((teachers: TeachersResponse) => {
                    this.userService.userIntroInfo['teachersAmount'] = teachers.count;
                    return this.mapTeacherInstrumentsAndGenres(teachers);
                })
            )
            /*  return this.httpClient.get(`${this.apiUrl}/matches/list/?page=${page}&limit=${limit}&schoolId=${schoolId}`, this.httpOptions).pipe(
                 map((response: any) => ({
                     list: response.list.map((item: any) => item.teacher),
                     pages: response.pages,
                     page: response.page,
                     count: response.count
                 })),
                 tap((teachers: any) => {
                     this.userService.userIntroInfo['teachersAmount'] = teachers.count;
                 })
             ); */
        }

        if (this.userService.userInfo.role === UserRoles.Student) {
            const studentId = this.userService.userInfo.studentId;
            return this.httpClient.get(`${this.apiUrl}/matches/student/${studentId}?page=${page}&limit=${limit}`, this.httpOptions).pipe(
                map((response: any) => ({
                    list: response.list.map((item: any) => ({
                        ...item.teacherUser,
                        acceptedStudent: item.acceptedStudent,
                        acceptedTeacher: item.acceptedTeacher,
                        lesson: item.lesson,
                    })),
                    pages: response.pages,
                    page: response.page,
                    count: response.count
                })),
                map((teachers: TeachersResponse) => {
                    this.userService.userIntroInfo['teachersAmount'] = teachers.count;
                    return this.mapTeacherInstrumentsAndGenres(teachers);
                })
            );
        }
        return this.httpClient.get<TeachersResponse>(`${this.apiUrl}/teachers/list/?page=${page}&limit=${limit}`, this.httpOptions).pipe(
            map((teachersResponse: TeachersResponse) => {
                this.userService.userIntroInfo['teachersAmount'] = teachersResponse.count;
                return this.mapTeacherInstrumentsAndGenres(teachersResponse);
            })
        );
    }


    getStudentList(page: string, limit: string): Observable<any> {
        if (this.userService.userInfo.role === UserRoles.Teacher) {
            const teacherId = this.userService.userInfo.teacherId;
            return this.httpClient.get<StudentsResponse>(`${this.apiUrl}/matches/teacher/${teacherId}?page=${page}&limit=${limit}`, this.httpOptions).pipe(
                map((response: any) => ({
                    list: response.list.map((item: any) => ({
                        ...item.studentUser,
                        acceptedStudent: item.acceptedStudent,
                        acceptedTeacher: item.acceptedTeacher,
                        lesson: item.lesson
                    })),
                    pages: response.pages,
                    page: response.page,
                    count: response.count
                })),
                map((response) => {
                    return this.mapStudentInstrumentsAndGenres(response);
                })
            );
        }
        if (this.userService.userInfo.role === UserRoles.Organisation && this.userService.userInfo.manageSchoolId) {
            const schoolId = this.userService.userInfo.manageSchoolId;
            return this.httpClient.get<StudentsResponse>(`${this.apiUrl}/students/list/?page=${page}&limit=${limit}&schoolId=${schoolId}`, this.httpOptions).pipe(
                map((students) => {
                    this.userService.userIntroInfo['studentsAmount'] = students.count;
                    return this.mapStudentInstrumentsAndGenres(students);
                })
            )
            /*  return this.httpClient.get(`${this.apiUrl}/matches/list/?page=${page}&limit=${limit}&schoolId=${schoolId}`, this.httpOptions).pipe(
                 map((response: any) => ({
                     list: response.list.map((item: any) => item.student),
                     pages: response.pages,
                     page: response.page,
                     count: response.count
                 })),
                 tap((students: any) => {
                     this.userService.userIntroInfo['studentsAmount'] = students.count;
                 })
             ); */
        }
        return this.httpClient.get<StudentsResponse>(`${this.apiUrl}/students/list/?page=${page}&limit=${limit}`, this.httpOptions).pipe(
            map((students) => {
                this.userService.userIntroInfo['studentsAmount'] = students.count;
                return this.mapStudentInstrumentsAndGenres(students);
            })
        );
    }

    getSortedStudents(page: string, limit: string, sortBy: string, direction: string): Observable<any> {
        if (this.userService.userInfo.role === UserRoles.Teacher) {
            const teacherId = this.userService.userInfo.teacherId;
            return this.httpClient.get(`${this.apiUrl}/matches/teacher/${teacherId}?page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions).pipe(
                map((response: any) => ({
                    list: response.list.map((item: any) => ({
                        ...item.studentUser,
                        acceptedStudent: item.acceptedStudent,
                        acceptedTeacher: item.acceptedTeacher
                    })),
                    pages: response.pages,
                    page: response.page,
                    count: response.count
                })),
                map((students) => {
                    return this.mapStudentInstrumentsAndGenres(students);
                })
            );
        }
        if (this.userService.userInfo.role === UserRoles.Organisation && this.userService.userInfo.manageSchoolId) {
            const schoolId = this.userService.userInfo.manageSchoolId;
            return this.httpClient.get<StudentsResponse>(`${this.apiUrl}/students/list/?page=${page}&limit=${limit}&schoolId=${schoolId}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions).pipe(
                map((students) => {
                    this.userService.userIntroInfo['studentsAmount'] = students.count;
                    return this.mapStudentInstrumentsAndGenres(students);
                })
            )
        }
        return this.httpClient.get<StudentsResponse>(`${this.apiUrl}/students/list/?page=${page}&limit=${limit}&sortBy=${sortBy}&direction=${direction}`, this.httpOptions).pipe(
            map((students) => {
                this.userService.userIntroInfo['studentsAmount'] = students.count;
                return this.mapStudentInstrumentsAndGenres(students);
            })
        );
    }

    deleteOrganisation(schoolId: string): Observable<any> {
        return this.httpClient.delete(`${this.apiUrl}/schools/${schoolId}`, this.httpOptions);
    }

    deleteTeacher(teacherId: string): Observable<any> {
        return this.httpClient.delete(`${this.apiUrl}/teachers/${teacherId}`, this.httpOptions);
    }

    deleteStudent(studentId: string): Observable<any> {
        return this.httpClient.delete(`${this.apiUrl}/students/${studentId}`, this.httpOptions);
    }

    userConfirmation(userId: string, key: string, role: string, password: { password: string }): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/auth/registration/${userId}/${key}?role=${role}`, password, this.httpOptions);
    }

    recovery(email: string): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}/auth/recovery`, {email}, this.httpOptions);
    }

    recoveryConfirmation(password: string, userId: string, key: string): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}/auth/recovery/${userId}/${key}`, {password}, this.httpOptions);
    }

    uploadFile(blob: Blob, id: string, role: string, files: Avatar[]): Observable<any> {
        const formData = new FormData();
        formData.append('file', blob);
        console.log(formData, 'formData')
        const type = UserRoles.Organisation === role ? 'school' : 'user';
        return this.httpClient.post(`${this.apiUrl}/files/${type}/${id}?type=avatar`, formData).pipe(
            switchMap((file: any) => {
                if (!files || files?.length === 0) {
                    return of(null);
                }
                const removeRequests: Observable<any>[] = files.map(file => {
                    return this.removeImage(id, file.id, type);
                });
                return forkJoin(removeRequests);
            })
        );
    }

    removeImage(userId: string, fileId: string, role: string): Observable<any> {
        return this.httpClient.delete(`${this.apiUrl}/files/${role}/${userId}/${fileId}`);
    }

    checkIfUserExist(email: string): Observable<any> {
        return this.httpClient.get(`${this.apiUrl}/users/${email}/exist`, this.httpOptions);
    }

    getIntegrationsList(schoolId: string): Observable<IntegrationListResponse> {
        return this.httpClient.get<IntegrationListResponse>(`${this.apiUrl}/integrations/list?schoolId=${schoolId}`, this.httpOptions);
    }

    getTeacherIntegrationsList(teacherId: string): Observable<IntegrationTeacherListResponse>{
        return this.httpClient.get<IntegrationTeacherListResponse>(`${this.apiUrl}/integrations/teacher/${teacherId}/list`, this.httpOptions);
    }

    addTeacherIntegration(data: { integrationId: string, teacherId: string, personalLink: string }) {
        return this.httpClient.post(`${this.apiUrl}/integrations/teacher`, data, this.httpOptions);
    }

    deleteTeacherIntegration(integrationId: string) {
        return this.httpClient.delete(`${this.apiUrl}/integrations/teacher/${integrationId}`, this.httpOptions);
    }

    updateTeacherIntegration(integrationId: string, personalLink: string ) {
        const data = {
            personalLink,
        }
        return this.httpClient.put(`${this.apiUrl}/integrations/teacher/${integrationId}`, data, this.httpOptions);
    }

    mapTeacherInstrumentsAndGenres(teachersResponse: TeachersResponse): TeachersResponse {
        const instrumentIds = teachersResponse.list.flatMap((teacher: Teacher) => teacher?.teacher?.instruments || [])
            .filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const genreIds = teachersResponse.list.flatMap((teacher: Teacher) => teacher?.teacher?.genres || [])
            .filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const instruments = this.musicService.getInstrumentsByID(instrumentIds);
        const genres = this.musicService.getGenresByID(genreIds);

        teachersResponse.list.forEach((teacher: Teacher) => {
            if (teacher?.teacher) {
                teacher.teacher.instruments = teacher.teacher.instruments
                    .map((instrumentId) => {
                        const instrument = instruments.find((inst) => typeof inst !== 'string' && inst?.id === instrumentId);
                        return instrument || null;
                    })
                    .filter((instrument) => instrument !== null);

                teacher.teacher.genres = teacher.teacher.genres
                    .map((genreId) => {
                        const genre = genres.find((genre) => typeof genre !== 'string' && genre?.id === genreId);
                        return genre || null;
                    })
                    .filter((genre) => genre !== null);
            }
        });
        return teachersResponse;
    }
    mapStudentInstrumentsAndGenres(studentsResponse: StudentsResponse): StudentsResponse {
        const instrumentIds = studentsResponse.list.flatMap((student: Student) => student?.student?.instruments || [])
            .filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const genreIds = studentsResponse.list.flatMap((student: Student) => student?.student?.genres || [])
            .filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const instruments = this.musicService.getInstrumentsByID(instrumentIds);
        const genres = this.musicService.getGenresByID(genreIds);

        studentsResponse.list.forEach((student: Student) => {
            if (student?.student) {
                student.student.instruments = student.student.instruments
                    .map((instrumentId) => {
                        const instrument = instruments.find((inst) => typeof inst !== 'string' && inst?.id === instrumentId);
                        return instrument || null;
                    })
                    .filter((instrument) => instrument !== null);

                student.student.genres = student.student.genres
                    .map((genreId) => {
                        const genre = genres.find((genre) => typeof genre !== 'string' && genre?.id === genreId);
                        return genre || null;
                    })
                    .filter((genre) => genre !== null);
            }
        });

        return studentsResponse;
    }

    mapProfileInstrumentsAndGenres(role: Student | Teacher) {
        const isStudent = (role as Student).student !== undefined;
        const profile = isStudent ? (role as Student).student : (role as Teacher).teacher;

        if (!profile) {
            return role;
        }

        const instrumentIds = (profile.instruments || [])
            .filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const genreIds = (profile.genres || [])
            .filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const instruments = this.musicService.getInstrumentsByID(instrumentIds);
        const genres = this.musicService.getGenresByID(genreIds);

        profile.instruments = profile.instruments
            .map((instrumentId) => {
                const instrument = instruments.find((inst) => typeof inst !== 'string' && inst?.id === instrumentId);
                return instrument || null;
            })
            .filter((instrument) => instrument !== null);

        profile.genres = profile.genres
            .map((genreId) => {
                const genre = genres.find((genre) => typeof genre !== 'string' && genre?.id === genreId);
                return genre || null;
            })
            .filter((genre) => genre !== null);

        return role;
    }




    mapMatchesTeacherInstrumentsAndGenres(teachers: Teacher[]) {
        const instrumentIds = teachers.flatMap((teacher: Teacher) => teacher?.teacher?.instruments || [])
            .filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const genreIds = teachers.flatMap((teacher: Teacher) => teacher?.teacher?.genres || [])
            .filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const instruments = this.musicService.getInstrumentsByID(instrumentIds);
        const genres = this.musicService.getGenresByID(genreIds);

        teachers.forEach((teacher: Teacher) => {
            if (teacher?.teacher) {
                teacher.teacher.instruments = teacher.teacher.instruments
                    .map((instrumentId) => {
                        const instrument = instruments.find((inst) => typeof inst !== 'string' && inst?.id === instrumentId);
                        return instrument || null;
                    })
                    .filter((instrument) => instrument !== null);

                teacher.teacher.genres = teacher.teacher.genres
                    .map((genreId) => {
                        const genre = genres.find((genre) => typeof genre !== 'string' && genre?.id === genreId);
                        return genre || null;
                    })
                    .filter((genre) => genre !== null);
            }
        });

        return teachers;
    }


    mapInstrumentsAndGenres(matchesResponse: MatchesListResponse): MatchesListResponse {
        const instrumentIds = matchesResponse.list.flatMap((match: Matches) =>
            [
                ...(match?.instruments || []),
                ...(match?.studentUser?.student?.instruments || []),
                ...(match?.teacherUser?.teacher?.instruments || [])
            ]
        ).filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const genreIds = matchesResponse.list.flatMap((match: Matches) =>
            [
                ...(match?.genres || []),
                ...(match?.studentUser?.student?.genres || []),
                ...(match?.teacherUser?.teacher?.genres || [])
            ]
        ).filter((id) => typeof id === 'string' && /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(id));

        const instruments = this.musicService.getInstrumentsByID(instrumentIds);
        const genres = this.musicService.getGenresByID(genreIds);

        const mapUserInstrumentsAndGenres = (user: { instruments: (string | MusicEntity | null)[], genres: (string | MusicEntity | null)[] }) => {
            user.instruments = user.instruments
                .map((instrumentId) => {
                    const instrument = instruments.find((inst) => typeof inst !== 'string' && inst?.id === instrumentId);
                    return instrument || null;
                })
                .filter((instrument) => instrument !== null);

            user.genres = user.genres
                .map((genreId) => {
                    const genre = genres.find((genre) => typeof genre !== 'string' && genre?.id === genreId);
                    return genre || null;
                })
                .filter((genre) => genre !== null);
        };

        matchesResponse.list.forEach((match: Matches) => {
            match.instruments = match.instruments
                .map((instrumentId) => {
                    const instrument = instruments.find((inst) => typeof inst !== 'string' && inst?.id === instrumentId);
                    return instrument || null;
                })
                .filter((instrument) => instrument !== null);

            match.genres = match.genres
                .map((genreId) => {
                    const genre = genres.find((genre) => typeof genre !== 'string' && genre?.id === genreId);
                    return genre || null;
                })
                .filter((genre) => genre !== null);

            if (match?.studentUser?.student) {
                mapUserInstrumentsAndGenres(match.studentUser.student);
            }

            if (match?.teacherUser?.teacher) {
                mapUserInstrumentsAndGenres(match.teacherUser.teacher);
            }
        });

        return matchesResponse;
    }





}
