import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UserRoles } from '../../../../core/models/user-info.interface';

@Component({
  selector: 'app-notify-lesson',
  templateUrl: './notify-lesson.component.html',
  styleUrls: ['./notify-lesson.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotifyLessonComponent {
    @Input() avatar: string;
    @Input() description: string;
    @Input() date?: string | Date;
    @Input() role: UserRoles;
    @Input() target?: string;
    protected readonly UserRoles = UserRoles;

    online() {
        window.open(this.target, '_blank');
    }
}
