<div class="info-container student-profile-info">
    <ng-container  *ngIf="!isMobilePlatform">
        <mat-toolbar class="toolbar-form">
            <span>{{ 'student_profile_info.toolbar_title' | translate }}</span>
            <span class="example-spacer"></span>
            <button mat-icon-button class="example-icon" (click)="closeDialog()">
                <mat-icon class="close-icon">close</mat-icon>
            </button>
        </mat-toolbar>
    </ng-container>
    <mat-divider class="mobile-divider"></mat-divider>

    <ng-container *ngIf="isMobilePlatform">
        <div class="toolbar-wrapper">
            <mat-toolbar class="toolbar-form">
                <button mat-icon-button class="example-icon" (click)="closeDialog()">
                    <mat-icon class="close-icon">chevron_left</mat-icon>
                </button>
                <span>{{ 'student_profile_info.toolbar_title' | translate }}</span>
                <div class="more-container">
                    <button mat-icon-button [matMenuTriggerFor]="menu">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu #menu="matMenu">
                        <button *ngIf="userRole === UserRoles.Organisation || userRole === UserRoles.Root" mat-menu-item
                                (click)="deleteStudent('student_profile_info.title.delete')">{{ 'student_profile_info.button.delete' | translate }}
                        </button>
                        <mat-divider
                            *ngIf="userRole === UserRoles.Organisation || userRole === UserRoles.Root"></mat-divider>
                        <button *ngIf="student.phoneNumber" mat-menu-item
                                (click)="contact(student.phoneNumber, 'phone')">
                            <span
                                class="menu-link">{{ 'student_profile_info.button.phone' | translate:{phoneNumber: (student.phoneNumber?.includes('+') ? student.phoneNumber : '+' + student.phoneNumber)} }}</span>
                        </button>
                        <mat-divider *ngIf="student.phoneNumber"></mat-divider>
                        <button mat-menu-item
                                (click)="contact(student.email, 'email')">{{ 'student_profile_info.button.email' | translate }}
                        </button>
                        <button *ngIf="userRole === UserRoles.Root || userRole === UserRoles.Student" mat-menu-item
                                (click)="editProfile()">{{ 'student_profile_info.button.edit' | translate }}
                        </button>
                        <ng-container *ngIf="userRole === UserRoles.Teacher">
                            <mat-divider></mat-divider>
                            <button [disabled]="!hasApprovePermission" *ngIf="student?.student?.teacherId" mat-menu-item
                                    (click)="handleDisapprove(student)">{{ 'student-dashboard-table.button.disapprove' | translate }}
                            </button>
                            <button [disabled]="!hasApprovePermission" *ngIf="!student?.student?.teacherId" mat-menu-item
                                    (click)="handleApprove(student)">{{ 'student-dashboard-table.button.approve' | translate }}
                            </button>
                            <mat-divider></mat-divider>
                            <button mat-menu-item [disabled]="!student?.lesson?.targetTeacher" (click)="teachOnline(student)">{{ 'notify-lesson.btn-teach' | translate }}</button>
                        </ng-container>
                    </mat-menu>
                </div>
            </mat-toolbar>
            <mat-divider></mat-divider>
        </div>
    </ng-container>


    <div class="info-card">
        <div class="image-wrapper">
<!--<div class="info-image-container"  [ngStyle]="{
  'background': this.image ? 'url(' + this.image + ')' : 'url(\'/assets/images/profile/unknown.jpg\')',
  'background-color': this.image ? '' : 'lightgray',
  'background-position': '50%',
  'background-size': 'cover',
  'background-repeat': 'no-repeat'
}">
                <div class="info-name">{{ student.firstName ? student.firstName : '—' }}</div>
            </div> -->
            <div class="info-block right-side-block">
                <div class="item">
                    <div class="label">{{ 'student_profile_info.labels.instruments' | translate }}</div>
                    <div class="value large">{{ translateInstruments(student.student?.instruments)  }}</div>
                </div>
                <div class="item p-bottom-1">
                    <div class="label">{{ 'student_profile_info.labels.genres' | translate }}</div>
                    <div class="value font">{{ translateGenres(student.student?.genres) }}</div>
                </div>
                <mat-divider></mat-divider>
                <div class="item hybrid">
                    <div class="item-block">
                        <div class="label">{{ 'student_profile_info.labels.language' | translate }}</div>
                        <div class="value">{{ student.spokenLanguages ? (student.spokenLanguages | capitalize) : '—' }}</div>
                    </div>
                    <mat-divider vertical></mat-divider>
                    <div class="item-block">
                        <div class="label">{{ 'student_profile_info.labels.location' | translate }}</div>
                        <div class="value">{{ student.city ? student.city : '—' }}</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </div>
        </div>

        <div class="info-card-content">
            <div class="info-block">
                <div class="item p-top-1">
                    <div class="label">{{ 'student_profile_info.labels.goals' | translate }}</div>
                    <div class="value">{{ student.student && student.student.description ? student.student.description : '—' }}</div>
                </div>
                <mat-divider></mat-divider>
                <div class="item hybrid">
                    <div class="item-block">
                        <div class="label">{{ 'student_profile_info.labels.skill_level' | translate }}</div>
                        <div class="value">{{ student.student && student.student.experienceLevel ? skillLevel(student.student.experienceLevel) : '—' }}</div>
                    </div>
                    <mat-divider vertical></mat-divider>
                    <div class="item-block">
                        <div class="label">{{ 'student_profile_info.labels.teaching_location' | translate }}</div>
                        <div class="value">{{ student.student && student.student.teachingLocation ? getTeachingLocation(student.student.teachingLocation) : '—' }}</div>
                    </div>
                </div>
                <mat-divider></mat-divider>
                <div class="item hybrid">
                    <div class="item-block">
                        <div class="label">{{ 'student_profile_info.labels.gender' | translate }}</div>
                        <div class="value">{{ translateGender(student.gender) }}</div>
                    </div>
                    <mat-divider vertical></mat-divider>
                    <div class="item-block">
                        <div class="label">{{ 'student_profile_info.labels.age' | translate }}</div>
                        <div class="value">{{ student.birthday | ageOrDash }}</div>
                    </div>
                </div>
                <ng-container *ngIf="student?.student?.socials">
                    <mat-divider></mat-divider>
                    <div class="item socials" *ngFor="let social of student?.student?.socials">
                        <a href="{{ social.link }}" target="_blank" class="label-link">{{ social.type }}</a>
                    </div>
                </ng-container>
                <mat-divider></mat-divider>
                <div class="btn-container custom-theme">
                    <button *ngIf="userRole === UserRoles.Organisation || userRole === UserRoles.Root" mat-stroked-button color="primary" (click)="deleteStudent('student_profile_info.title.delete')">{{ 'student_profile_info.button.delete' | translate }}</button>
                    <button *ngIf="student.phoneNumber" mat-stroked-button color="primary" (click)="contact(student.phoneNumber, 'phone')">{{ 'student_profile_info.button.phone' | translate:{ phoneNumber: (student.phoneNumber?.includes('+') ? student.phoneNumber : '+' + student.phoneNumber) } }}</button>
                    <button mat-stroked-button color="primary" (click)="contact(student.email, 'email')">{{ 'student_profile_info.button.email' | translate }}</button>
                    <button *ngIf="userRole === UserRoles.Root || userRole === UserRoles.Student" mat-stroked-button color="primary" (click)="editProfile()">{{ 'student_profile_info.button.edit' | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>
