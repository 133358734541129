<div class="header-wrapper">
    <app-header [hideBetaProgram]="true" [mobileWhiteLogo]="true"></app-header>
</div>
    <div class="main-container">
        <div class="login-wrapper">
            <div class="lang-btn-container">
                <button mat-stroked-button (click)="changeLanguage()">{{ chosenLanguage }}</button>
            </div>
            <ng-container *ngIf="!(showResetPassword$ | async)">
            <div class="form-content">
                <form [formGroup]="loginForm">
                    <div class="form-title personal-data" [innerHTML]="(successfullyLoggedOut$ | async) ? ('login.successfully-logged-out' | translate) : ('login.title' | translate)"></div>
                    <div class="form-row">
                        <div class="form-column">
                            <div class="form-field">
                                <p>{{ 'login.email-label' | translate }}</p>
                                <mat-form-field subscriptSizing="dynamic">
                                    <input (focus)="onFocus()" type="text" matInput formControlName="email" appTrim required>
                                </mat-form-field>
                                <mat-error *ngIf="getFieldProperties('email').hasError">
                                    {{ 'login.validation.email.invalid' | translate }}
                                </mat-error>
                                <mat-error *ngIf="getFieldProperties('email').isRequired">
                                    {{ 'login.validation.email.required' | translate }}
                                </mat-error>
                                <mat-error *ngIf="emailErrorMessage">
                                    {{ 'login.' + emailErrorMessage | translate }}
                                </mat-error>
                            </div>
                            <div class="form-field">
                                <p>{{'login.password-label' | translate}}</p>
                                <div class="password-wrapper">
                                    <mat-form-field subscriptSizing="dynamic">
                                        <input (focus)="onFocus()" #passwordInput matInput formControlName="password" class="input-password-mask">
                                        <svg (mousedown)="togglePasswordType($event, passwordInput, 1)" class="eye-icon" xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
                                            <path d="M12.5 16C13.75 16 14.8125 15.5625 15.6875 14.6875C16.5625 13.8125 17 12.75 17 11.5C17 10.25 16.5625 9.1875 15.6875 8.3125C14.8125 7.4375 13.75 7 12.5 7C11.25 7 10.1875 7.4375 9.3125 8.3125C8.4375 9.1875 8 10.25 8 11.5C8 12.75 8.4375 13.8125 9.3125 14.6875C10.1875 15.5625 11.25 16 12.5 16ZM12.5 14.2C11.75 14.2 11.1125 13.9375 10.5875 13.4125C10.0625 12.8875 9.8 12.25 9.8 11.5C9.8 10.75 10.0625 10.1125 10.5875 9.5875C11.1125 9.0625 11.75 8.8 12.5 8.8C13.25 8.8 13.8875 9.0625 14.4125 9.5875C14.9375 10.1125 15.2 10.75 15.2 11.5C15.2 12.25 14.9375 12.8875 14.4125 13.4125C13.8875 13.9375 13.25 14.2 12.5 14.2ZM12.5 19C10.0667 19 7.85 18.3208 5.85 16.9625C3.85 15.6042 2.4 13.7833 1.5 11.5C2.4 9.21667 3.85 7.39583 5.85 6.0375C7.85 4.67917 10.0667 4 12.5 4C14.9333 4 17.15 4.67917 19.15 6.0375C21.15 7.39583 22.6 9.21667 23.5 11.5C22.6 13.7833 21.15 15.6042 19.15 16.9625C17.15 18.3208 14.9333 19 12.5 19Z" fill="#C97D70"/>
                                            <line class="cross-out-1" x1="5" y1="3" x2="20" y2="20" stroke="#fff" stroke-width="2"/>
                                        </svg>
                                    </mat-form-field>
                                </div>
                                <mat-error *ngIf="getFieldProperties('password').isRequired">
                                    {{ 'login.password.required' | translate }}
                                </mat-error>
                                <mat-error *ngIf="passErrorMessage">
                                    {{ 'login.' + passErrorMessage | translate }}
                                </mat-error>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="forgot-password">
                    <a (click)="forgotPassword()">{{'login.forgot-password' | translate}}</a>
                </div>
            </div>
            <div class="footer">
                <div class="btn-container custom-theme">
                    <div class="sign-up-container">
                        {{'login.no-account-yet' | translate}} <a (click)="signUp()">{{'login.sign-up-link' | translate}}</a>
                    </div>
                    <button mat-raised-button color="primary" type="submit" (click)="onSubmit()">{{'login.login-btn' | translate}}</button>
                </div>
            </div>
            </ng-container>
            <ng-container *ngIf="(showResetPassword$ | async)">
                <app-reset-password (onCancel)="onPasswordResetCancel()"></app-reset-password>
            </ng-container>
        </div>
        <div class="image-container"></div>
    </div>
