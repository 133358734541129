<div class="activate-form-container">
    <mat-toolbar class="toolbar-form">
        <span>{{'integration.activate_digital_stage' | translate}}</span>
        <span class="example-spacer"></span>
        <button mat-icon-button class="example-icon" (click)="closeDialog()">
            <mat-icon class="close-icon">close</mat-icon>
        </button>
    </mat-toolbar>

    <div mat-dialog-content class="form-content">
        <mat-stepper class="medium-block" [disableRipple]="true" [orientation]="'vertical'" [linear]="true" #stepper>
            <mat-step [stepControl]="default"
                      [completed]="false"
                      [optional]="false">
                <ng-template matStepLabel><p class="label"> {{'integration.create_account' | translate}}</p></ng-template>
                <div class="wrapper">
                    <div class="title">
                        {{'integration.go_to_providers_website_and_create_account' | translate}}
                    </div>
                    <div class="btn-container custom-theme">
                        <button mat-stroked-button class="ellipsis" color="primary" type="submit" (click)="goTo()">
                            {{'integration.go_to_digital_stage' | translate}}
                        </button>
                    </div>
                </div>
            </mat-step>

            <mat-step
                [stepControl]="linkFormGroup"
                [completed]="false"
                [optional]="false">
                <ng-template matStepLabel><p class="label">Add your stage link</p></ng-template>
                <div class="wrapper">
                    <div class="title">
                        {{'integration.paste_link_to_stage_here' | translate}}
                    </div>
                    <form [formGroup]="linkFormGroup">
                        <div class="form-field">
                            <mat-form-field subscriptSizing="dynamic">
                                <input matInput formControlName="link"
                                       placeholder="{{'integration.room_id_placeholder' | translate : {placeholderLink: this.placeholderLink} }}">
                            </mat-form-field>
                        </div>
                    </form>
                </div>
            </mat-step>
        </mat-stepper>
    </div>

    <div class="footer">
        <div class="btn-container custom-theme">
            <div class="right-side">
                <button mat-stroked-button color="primary" (click)="closeDialog()">
                    {{ 'select_upload_image.cancel' | translate }}
                </button>
                <button mat-raised-button class="ellipsis" color="primary" type="submit" [disabled]="linkFormGroup.invalid || loading"  (click)="onSubmit()">
                    {{'integration.activate_digital_stage' | translate}}
                </button>
            </div>
        </div>
    </div>
</div>

