import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UserService } from '../../../services/user/user.service';
import { UserInfo, UserRoles } from '../../../../core/models/user-info.interface';
import { ApiService } from '../../../services/api/api.service';
import {
    Avatar, IntegratedTeacher, Integration,
    Lesson,
    LessonsList, MatchesListResponse, SchoolItem,
    SchoolListResponse,
    Student,
    StudentLesson,
    StudentsResponse, Teacher,
    TeachersResponse
} from '../../../services/api/api.type';
import { concatMap, map, mergeMap, Observable, of, switchMap } from 'rxjs';
import { DashboardTableService } from '../../../services/dashboard-table.service';
import { TranslateService } from '@ngx-translate/core';
import { OrganisationProfileService } from '../../../../shared/services/org-profile.service';
import { TeacherProfileService } from '../../../../shared/services/teacher-profile.service';
import { PlatformService } from '../../../services/platform.service';
import { StudentProfileInfoComponent } from '../student-dashboard/student-profile-info/student-profile-info.component';
import { MatchesListComponent } from '../organisation-dashboard/matches-list/matches-list.component';
import {
    SchoolApproveEvent
} from '../organisation-dashboard/organisation-dashboard-table/organisation-dashboard-table.component';
import {
    OrganisationProfileInfoComponent
} from '../organisation-dashboard/organisation-profile-info/organisation-profile-info.component';
import { TeacherProfileInfoComponent } from '../teacher-dashboard/teacher-profile-info/teacher-profile-info.component';
import { TeacherApproveEvent } from '../teacher-dashboard/dashboard-teacher-table/dashboard-teacher-table.component';
import { MusicEntity } from '../../../../shared/services/music.service';

@Component({
  selector: 'app-root-dashboard',
  templateUrl: './root-dashboard.component.html',
  styleUrls: ['./root-dashboard.component.scss']
})
export class RootDashboardComponent implements OnInit {
    teachers: TeachersResponse;
    students: StudentsResponse;
    organisations: SchoolListResponse;
    newOrganisations: SchoolListResponse;
    organisation: SchoolItem;
    loadMatchList = false;
    activeMatches: MatchesListResponse;
    pendingMatches: MatchesListResponse;
    pastMatches: MatchesListResponse;
    teacher: Teacher;
    studentLessons: StudentLesson[] = [];
    teacherNotifyData: { earliestLesson: string | Date; student: Student | undefined };
    avatarFileUrl = '';
    files: Avatar[];
    showBanner = true;
    integrationList: Integration[] = [];
    integrationTeacherList: IntegratedTeacher[] = [];
    @Input() instrumentsData: { top10: MusicEntity[]; notTop10: MusicEntity[]; allItems: MusicEntity[] };
    @Input() genresData: { top10: MusicEntity[]; notTop10: MusicEntity[]; allItems: MusicEntity[] };

    protected readonly UserRoles = UserRoles;

    @ViewChild('matchesList') matchesListComponent!: MatchesListComponent;

    get user(): UserInfo {
        return this.userService.userInfo;
    }

    get hasSchoolConfirmedAndValidated(): boolean {
        return (!!this.organisation?.confirmed && !!this.organisation?.validated);
    }

    get hasTeacherConfirmedAndValidated(): boolean {
        return !!this.teacher?.teacher?.validated;
    }

    get isMobilePlatform(): boolean {
        return this.platformService.isMobile;
    }

    constructor(
        private userService: UserService,
        private orgProfileService: OrganisationProfileService,
        private teacherProfileService: TeacherProfileService,
        private translateService: TranslateService,
        private apiService: ApiService,
        private platformService: PlatformService,
        private dashboardTableService: DashboardTableService) {
    }

    get userIntroInfo() {
        return this.userService.userIntroInfo;
    }

    ngOnInit() {
            switch (this.user.role) {
                case UserRoles.Root:
                    this.getTeachers();
                    this.getStudents();
                    this.getOrganisations();
                    break;

                case UserRoles.Organisation:
                    this.getTeachers();
                    this.getStudents();
                    this.getOrganisation();
                    this.getMatches();
                    break;

                case UserRoles.Teacher:
                    this.getStudents();
                    this.getTeacher(false, true);
                    break;

                case UserRoles.Student:
                    this.getTeachers();
                    break;

                default:
                    break;
            }
    }

    getTeachers() {
        this.apiService.getTeachers( '1', '1000').subscribe(
            (teachers) => this.teachers = teachers);
    }

    getTeacher(editProfile = false, getIntegrations = false) {
        const teacherId = this.user?.teacherId || '';
        if (getIntegrations) {
            if (teacherId) {
                this.apiService.getTeacher(teacherId).pipe(
                    switchMap((teacher) => {
                        this.teacher = teacher;
                        this.avatarFileUrl = this.teacher.files[0] && this.teacher.files[0].url;
                        this.files = this.teacher.files;
                        return this.getIntegrations(teacher.teacher.schoolId, teacher.teacher.id)
                    }),
                ).subscribe(
                    (teacherIntegrations) => {
                        this.integrationTeacherList = teacherIntegrations.list;
                    });
            }
            return;
        }

        if (teacherId) {
            this.apiService.getTeacher(teacherId).subscribe(
                (teacher) => {
                    this.teacher = teacher;
                    this.avatarFileUrl = this.teacher.files[0] && this.teacher.files[0].url;
                    this.files = this.teacher.files;
                    if (editProfile) {
                        this.teacherProfileService.updateTeacher(teacher);
                    }
                });
        }
    }

    getStudents(modalData?: {modal: StudentProfileInfoComponent, id: string}) {
        this.apiService.getStudentList( '1', '1000').pipe(
            mergeMap((students) => {
                this.students = students;
                if (this.userService.userInfo.role === UserRoles.Teacher) {
                    return this.getEarliestLessonForEachStudent(students.list);
                }
                return of(null)
            })
        ).subscribe((data) => {
            if (data) {
                this.teacherNotifyData = data;
            }
            if (modalData && this.platformService.isMobile) {
                const selectedStudent = this.students.list.find((student:Student) => student?.student?.id === modalData?.id);
                if (selectedStudent) {
                    modalData?.modal.setStudent(selectedStudent);
                }
            }
        });
    }

    getOrganisations() {
        this.apiService.getOrganisations('1', '1000').subscribe((organisations) => {
            this.organisations = organisations;
            const unvalidatedOrganizations = organisations.list.filter((org) => !org.validated);
            this.newOrganisations = {
                ...organisations,
                list: unvalidatedOrganizations,
                count: unvalidatedOrganizations.length,
            };
        });
    }

    getOrganisation(editProfile = false) {
        this.apiService.getOrganisation(this.user.manageSchoolId).subscribe((organisation) => {
            this.organisation = organisation;
            this.avatarFileUrl = this.organisation.files[0] && this.organisation.files[0].url;
            this.files = this.organisation.files;
            if (editProfile) {
                this.orgProfileService.updateSchool(organisation);
            }
        });
    }

    updateActiveMatches() {
        this.matchesListComponent.getMatches();
    }

    getMatches() {
        if (this.user.manageSchoolId) {
            const manageSchoolId = this.user.manageSchoolId;
            this.apiService.getMatchesList(manageSchoolId, '1', '100', 'active').pipe(
                switchMap((activeMatches) => {
                    this.activeMatches = activeMatches;
                    return this.apiService.getMatchesList(manageSchoolId, '1', '100', 'pending');
                }),
                switchMap((pendingMatches) => {
                    this.pendingMatches = pendingMatches;
                    return this.apiService.getMatchesList(manageSchoolId, '1', '100', 'past')
                })
            ).subscribe((pastMatches) => {
                this.pastMatches = pastMatches;
                this.userService.userIntroInfo['acceptedMatches'] = this.activeMatches.list.length;
                this.userService.userIntroInfo['pendingMatches'] = this.pendingMatches.list.length;
                this.userService.userIntroInfo['pastMatches'] = this.pastMatches.list.length;
                this.loadMatchList = true;
            });
        }
    }

    getEarliestLessonForEachStudent(students: Student[]): Observable<{
        earliestLesson: string | Date;
        student: Student | undefined;
    }> {
        const teacherLessons$ = this.apiService.getLessonsList(this.userService.userInfo.userId).pipe(
            map((lessons: LessonsList) => {
                const earliestLesson = lessons.list.length > 0 ? this.findEarliestLesson(lessons.list) : null;
                const student = earliestLesson ? students.find(student => student.student.id === earliestLesson.studentId) : undefined;
                return {
                    student,
                    earliestLesson
                };
            })
        );

        return teacherLessons$.pipe(
            map(({ student, earliestLesson }) => ({
                student,
                earliestLesson: earliestLesson
                    ? new Date(earliestLesson.startTime)
                    : '',
            }))
        );
    }

    findEarliestLesson(lessons: Lesson[]): Lesson {
        return <Lesson>lessons.reduce((earliest: Lesson | null, current) => {
            if (!earliest || (current && current.startTime < earliest.startTime)) {
                return current;
            }
            return earliest;
        }, null);
    }

    updateTeacherValidation({teacherId, validated, modal}: TeacherApproveEvent<TeacherProfileInfoComponent>) {
        this.apiService.updateTeacherValidation(teacherId,{validated}).pipe(
            mergeMap(() => {
                    return this.apiService.getTeachers('1', '1000');
            }
        )).subscribe((teachers) => {
            this.teachers = teachers
            this.dashboardTableService.needUpdateTable.next(null);
            if (this.platformService.isMobile) {
                const selectedTeacher = this.teachers.list.find((teacher: Teacher) => teacher?.teacher.id === teacherId);
                if (selectedTeacher) {
                    modal?.setTeacher(selectedTeacher);
                }
            }
        });
    }

    updateStudentValidation({studentId, validated}: { studentId: string, validated: boolean }) {
    }

    updateOrganisationValidation({organisationId, validated, modal}: SchoolApproveEvent<OrganisationProfileInfoComponent> ) {
        this.apiService.updateSchoolValidation(organisationId,{validated}).pipe(
            mergeMap(() =>  this.apiService.getOrganisations('1', '1000')
            )).subscribe((organisations) => {
            this.organisations = organisations;
            const unvalidatedOrganizations = organisations.list.filter((org) => !org.validated);
            this.newOrganisations = {
                ...organisations,
                list: unvalidatedOrganizations,
                count: unvalidatedOrganizations.length,
            };
            this.dashboardTableService.needUpdateOrganisationTable.next(null);
            if (this.platformService.isMobile) {
                const selectedOrganisation = this.organisations.list.find((organisation: SchoolItem) => organisation?.id === organisationId);
                if (selectedOrganisation && modal) {
                    modal.setSchool(selectedOrganisation);
                }
            }
        });
    }

    mailTo(type: string) {
        let email = '';
        const subject = this.translateService.instant('notify-panel.mail-subject')
        if (type === 'feedback') {
            email = `mailto:support@musiq.me?subject=${encodeURIComponent(subject)}`;
        } else if (type === 'admin') {
            email = `mailto:admin@musiq.me?subject=${encodeURIComponent(subject)}`;
        }
        window.open(email, '_blank');
    }

    avatarChange() {
        switch (this.user.role) {
            case UserRoles.Organisation:
                this.getOrganisation();
                break;

            case UserRoles.Teacher:
                this.getTeacher(false);
                break;
            default:
                break;
        }
    }

    onEdit() {
        switch (this.user.role) {
            case UserRoles.Organisation:
                this.getTeachers();
                this.getOrganisation(true);
                break;

            case UserRoles.Teacher:
                this.getTeacher(true);
                break;

            default:
                break;
        }
    }

    onCloseBanner() {
        this.showBanner = false;
    }

    getIntegrations(schoolId: string, teacherId: string) {
        return this.apiService.getIntegrationsList(schoolId).pipe(
            concatMap((integrationList) => {
                this.integrationList = integrationList.list.filter(integration => integration.name !== 'Sirius');
                return this.apiService.getTeacherIntegrationsList(teacherId);
            })
        );
    }

}
