<app-dashboard-header (onEdit)="onEdit()"></app-dashboard-header>
<app-notify-banner *ngIf="showBanner" [role]="user.role" (buttonClick)="mailTo('feedback')" [buttonText]="'notify-panel.feedback-button'" [title]="'notify-panel.title'" (closeEvent)="onCloseBanner()"></app-notify-banner>
<app-dashboard-intro [role]="user.role"
                     [fileUrl]="avatarFileUrl"
                     [schoolName]="organisation?.name"
                     [teacherNotifyData]="teacherNotifyData"
                     (onAvatarChange)="avatarChange()"
                     [files]="files"
                     [userIntroInfo]="userIntroInfo">
</app-dashboard-intro>
<app-notify-banner
    *ngIf="organisation && user.role === UserRoles.Organisation && !hasSchoolConfirmedAndValidated"
    [backgroundColor]="'#C97D70'"
    [buttonText]="'notify-panel.contact-admin'"
    [iconColor]="'#FFF'"
    [textColor]="'#FFF'"
    [btnColor]="'#FFF'"
    [role]="user.role"
    [title]="'notify-panel.org-confirm'"
    (buttonClick)="mailTo('admin')">
</app-notify-banner>
<app-notify-banner
    *ngIf="teacher && user.role === UserRoles.Teacher && !hasTeacherConfirmedAndValidated"
    [backgroundColor]="'#C97D70'"
    [buttonText]="'notify-panel.contact-admin'"
    [iconColor]="'#FFF'"
    [textColor]="'#FFF'"
    [btnColor]="'#FFF'"
    [role]="user.role"
    [title]="'notify-panel.teacher-confirm'"
    (buttonClick)="mailTo('admin')">
</app-notify-banner>
<app-avatar-upload *ngIf="user.role !== UserRoles.Root && !avatarFileUrl && !isMobilePlatform" [files]="files" [role]="user.role" (onAvatarChange)="avatarChange()"></app-avatar-upload>
<div class="table-wrapper" [ngClass]="{'integration-wrapper': user.role === UserRoles.Teacher && (students?.list && students.list.length > 0 && (integrationTeacherList.length > 0 || integrationList.length > 0))}">
    <ng-container *ngIf="!isMobilePlatform">
        <app-organisation-dashboard-table
            *ngIf="organisations && user.role === UserRoles.Root"
            [organisations]="organisations"
            [role]="user.role"
            (onApprove)="updateOrganisationValidation($event)">
        </app-organisation-dashboard-table>
        <app-dashboard-teacher-table
            *ngIf="teachers && (user.role === UserRoles.Root || user.role === UserRoles.Organisation)"
            [teachers]="teachers"
            [role]="user.role"
            [organisation]="organisation"
            (onApprove)="updateTeacherValidation($event)">
        </app-dashboard-teacher-table>
        <app-dashboard-student-table
            *ngIf="students && (user.role === UserRoles.Root || user.role === UserRoles.Organisation || user.role === UserRoles.Teacher)"
            [students]="students"
            [teacher]="teacher"
            [ngStyle]="{ maxWidth: user.role === UserRoles.Teacher && (students?.list && students.list.length > 0) && (integrationTeacherList.length > 0 || integrationList.length > 0) ? 'calc(100vw - 368px)' : 'inherit'}"
            [studentLessons]="studentLessons"
            (onLessonTimeSelect)="getStudents()"
            (onApprove)="updateStudentValidation($event)">
        </app-dashboard-student-table>
        <app-instruments-table
            *ngIf="instrumentsData && instrumentsData.allItems && (user.role === UserRoles.Root)"
            [instruments]="instrumentsData.allItems">
        </app-instruments-table>
        <app-genres-table
            *ngIf="genresData && genresData.allItems && (user.role === UserRoles.Root)"
            [genres]="genresData.allItems">
        </app-genres-table>
        <app-integrations
            *ngIf="user.role === UserRoles.Teacher && teacher && (students?.list && students.list.length > 0) && (integrationTeacherList.length > 0 || integrationList.length > 0)"
            [integrationTeacherList]="integrationTeacherList"
            [integrationList]="integrationList"
            [teacher]="teacher"
            (onUpdateIntegrations)="getTeacher(false, true)"
        ></app-integrations>
        <ng-container *ngIf="user.role === UserRoles.Organisation && loadMatchList">
            <app-pending-matches (updateActiveMatches)="updateActiveMatches()" [matches]="pendingMatches"></app-pending-matches>
            <app-matches-list #matchesList [matches]="activeMatches"></app-matches-list>
            <app-past-matches [matches]="pastMatches"></app-past-matches>
        </ng-container>
    </ng-container>
    <ng-container *ngIf="isMobilePlatform">
        <app-organisation-list-mobile
            *ngIf="organisations && user.role === UserRoles.Root || user.role === UserRoles.Organisation"
            [role]="user.role"
            [organisations]="organisations"
            (onApproveOrg)="updateOrganisationValidation($event)"
            [userIntroInfo]="userIntroInfo"
            [teachers]="teachers"
            [organisation]="organisation"
            [newOrganisations]="newOrganisations"
            (onApproveTeacher)="updateTeacherValidation($event)"
            [students]="students"
            [teacher]="teacher"
            [activeMatches]="activeMatches"
            [pendingMatches]="pendingMatches"
            [pastMatches]="pastMatches"
            [studentLessons]="studentLessons"
            [instruments]="instrumentsData.allItems"
            [genres]="genresData.allItems"
            (onLessonTimeSelect)="getStudents()"
            (updateTeacherList)="getTeachers()"
            (updateMatches)="getMatches()"
            (updateOrganisationList)="getOrganisations()"
            (onApprove)="updateOrganisationValidation($event)"
            (onApproveStudent)="updateStudentValidation($event)"
            (updatePendingMatches)="getMatches()"
        ></app-organisation-list-mobile>
        <app-student-list-mobile
            *ngIf="students && user.role === UserRoles.Teacher"
            [students]="students"
            [teacher]="teacher"
            [role]="user.role"
            [studentLessons]="studentLessons"
            [teacherNotifyData]="teacherNotifyData"
            (onLessonTimeSelect)="getStudents($event)"
            (onApprove)="updateStudentValidation($event)"
        ></app-student-list-mobile>
        <app-integrations
            *ngIf="user.role === UserRoles.Teacher && teacher && (students?.list && students.list.length > 0) && (integrationTeacherList.length > 0 || integrationList.length > 0)"
            [integrationTeacherList]="integrationTeacherList"
            [integrationList]="integrationList"
            [teacher]="teacher"
            (onUpdateIntegrations)="getTeacher(false, true)"
        ></app-integrations>
    </ng-container>
</div>
