import { Directive, HostListener, ElementRef, OnInit } from '@angular/core';
import { AbstractControl, ControlContainer } from '@angular/forms';

@Directive({
    selector: '[appTrim]'
})
export class TrimDirective implements OnInit {
    private formControl: AbstractControl<any> | null;

    constructor(
        private el: ElementRef,
        private controlContainer: ControlContainer
    ) {}

    ngOnInit(): void {
        const controlName = this.el.nativeElement.getAttribute('formControlName');

        if (controlName && this.controlContainer.control) {
            this.formControl = this.controlContainer.control.get(controlName);

            if (this.formControl) {
                this.formControl.valueChanges.subscribe((value: string) => {
                    if (value && typeof value === 'string') {
                        const trimmedValue = value.trim();
                        if (value !== trimmedValue) {
                            this.formControl?.setValue(trimmedValue, { emitEvent: false });
                        }
                    }
                });
            }
        }
    }

    @HostListener('input', ['$event'])
    onInput(event: Event): void {
        const input = this.el.nativeElement as HTMLInputElement;
        let value = input.value;

        const noSpaces = value.replace(/\s/g, '');

        input.value = noSpaces;

        if (this.formControl && this.formControl.value !== noSpaces) {
            this.formControl.setValue(noSpaces, { emitEvent: false });
        }
    }
}
