import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { HttpClientModule } from '@angular/common/http';
import { MusiqRoutingModule } from '../music/musiq-routing.module';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatStepperModule } from '@angular/material/stepper';
import { HeaderComponent } from '../music/components/header/header.component';
import { PageNotFoundComponent } from '../music/components/page-not-found/page-not-found.component';
import { NotifyPanelComponent } from '../music/components/notify-panel/notify-panel.component';
import { NumericInputDirective } from './directives/numeric-Input.directive';
import { AlphanumericOnlyDirective } from './directives/alphanumeric-only.directive';
import { CurrencyDirective } from './directives/currency.directive';
import { TabNavigationDirective } from './directives/tab-navigation.directive';
import { TooltipDirective } from './directives/tooltip.directive';
import { TrimDirective } from './directives/app-trim.directive';

const components = [
   HeaderComponent, PageNotFoundComponent, NotifyPanelComponent, NumericInputDirective,
    AlphanumericOnlyDirective, CurrencyDirective, TabNavigationDirective, TooltipDirective, TrimDirective
];

@NgModule({
  declarations: [
    ...components,
  ],
  imports: [
      CommonModule,
      HttpClientModule,
      MusiqRoutingModule,
      MatButtonModule,
      MatListModule,
      MatCheckboxModule,
      MatExpansionModule,
      TranslateModule,
      MatRadioModule,
      MatButtonModule,
      FormsModule,
      ReactiveFormsModule,
      MatFormFieldModule,
      MatInputModule,
      MatSelectModule,
      MatCheckboxModule,
      MatExpansionModule,
      MatIconModule,
      MatListModule,
      MatDialogModule,
      MatToolbarModule,
      MatGridListModule,
      MatChipsModule,
      MatTableModule,
      MatPaginatorModule,
      MatStepperModule
  ],
    exports: [
        ...components
    ],
  providers: [
  ],
})
export class SharedModule { }
