<div class="lesson-notify-container">
    <div class="image" [ngStyle]="{ 'background': avatar ? 'url(' + avatar + ') lightgray 50% / cover no-repeat' : 'url(\'/assets/images/profile/unknown.jpg\') lightgray 50% / cover no-repeat' }"></div>
    <div class="info">
        <div class="description">{{description}}</div>
        <div class="date">{{date | localizedDateTime}}</div>
        <div class="btn-container custom-theme">
            <button *ngIf="target" mat-stroked-button color="primary" (click)="online()">{{ role === UserRoles.Teacher ? ('notify-lesson.btn-teach' | translate) : ('notify-lesson.btn-learn' | translate) }}</button>
        </div>
    </div>
</div>
