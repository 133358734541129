<div class="form-content">
    <ng-container *ngIf="(showSuccess$ | async)">
        <div class="title-container">
            <div class="form-title personal-data">{{'reset-password.success.message' | translate}}</div>
            <div class="form-description">{{'reset-password.email.check' | translate}}</div>
        </div>
    </ng-container>
    <ng-container *ngIf="!(showSuccess$ | async)">
    <div class="form-title personal-data">{{'reset-password.title' | translate}}</div>
    <div class="form-description">{{'reset-password.sub-title' | translate}}</div>
    <form [formGroup]="confirmResetPasswordForm">
        <div class="form-row">
            <div class="form-column">
                <div class="form-field">
                    <p>Email</p>
                    <mat-form-field subscriptSizing="dynamic">
                        <input placeholder="{{'reset-password.sub-title' | translate}}" matInput formControlName="email" appTrim required (focus)="resetError()">
                    </mat-form-field>
                    <mat-error
                        *ngIf="getFieldProperties('email', confirmResetPasswordForm).isRequired">
                        {{'reset-password.email.required' | translate}}
                    </mat-error>
                    <mat-error *ngIf="getFieldProperties('email', confirmResetPasswordForm).hasError">
                        {{'reset-password.email.invalid' | translate}}
                    </mat-error>
                    <mat-error *ngIf="(showError$ | async)">
                        {{ 'reset-password.user.notfound' | translate:{ email: email?.value } }}
                    </mat-error>
                </div>
            </div>
        </div>
    </form>
    </ng-container>
</div>

<div class="footer">
    <div class="btn-container custom-theme">
        <button mat-stroked-button color="primary" (click)="cancel()">{{!(showSuccess$ | async) ? ('reset-password.cancel-btn' | translate) : ('reset-password.go-to-login-btn' | translate) }}</button>
        <button *ngIf="!(showSuccess$ | async)" mat-raised-button color="primary" [disabled]="confirmResetPasswordForm.invalid" type="submit" (click)="onSubmit()">{{'reset-password.reset-password-btn' | translate}}</button>
    </div>
</div>
